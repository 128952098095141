import React, { useState } from "react";

import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton,
} from "@multiversx/sdk-dapp/UI";
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";

import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { useEffect } from "react";

const unsetButton = (elem) => styled(elem)`
  // all: unset;
  // text-align: center;
  // padding: 1em;
  border: unset;
  // width: 90%;
  // cursor: pointer;
  color: black;
  font-weight: 500;
  border: unset !important;
  background-color: #08fbae !important;
  &:hover {
    border: unset !important;
    box-shadow: unset !important;
    background-color: #08fb7d !important;
  }
  &:focus {
    border: unset !important;
    box-shadow: unset !important;
    background-color: #08fb7d !important;
  }
`;

const UnsetWalletConnectLoginButton = unsetButton(WalletConnectLoginButton);
const UnsetExtensionLoginButton = unsetButton(ExtensionLoginButton);
const UnsetLedgerLoginButton = unsetButton(LedgerLoginButton);
const UnsetWebWalletLoginButton = unsetButton(WebWalletLoginButton);

function SignIn() {
  const { isLoggedIn } = useGetLoginInfo();

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = "/";
    }
  }, [isLoggedIn]);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Container maxWidth="xs">
        <Stack>
          <Box component="img" src="images/xFirstLogo.png"></Box>
          <UnsetWalletConnectLoginButton
            callbackRoute="/"
            isWalletConnectV2={true}
          >
            xPortal login
          </UnsetWalletConnectLoginButton>
          <UnsetExtensionLoginButton callbackRoute="/">
            Extension login
          </UnsetExtensionLoginButton>
          <UnsetLedgerLoginButton callbackRoute="/">
            Ledger login
          </UnsetLedgerLoginButton>
          <UnsetWebWalletLoginButton callbackRoute="/">
            Web Wallet login
          </UnsetWebWalletLoginButton>
        </Stack>
      </Container>
    </Box>
  );
}

export default SignIn;
