import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Card, Dialog, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  ExtensionLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton,
} from "@multiversx/sdk-dapp/UI";
import { LoginBoxContext } from ".";
import "./styles.css";

const unsetButton = (elem) => styled(elem)`
  all: unset;
  text-align: center;
  padding: 1em;
  border: 1px solid white;
  width: 90%;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: white;
    color: black;
    border: 1px solid white !important;
    outline: none !important;
    box-shadow: none;
  }
`;
const UnsetWalletConnectLoginButton = unsetButton(WalletConnectLoginButton);
const UnsetExtensionLoginButton = unsetButton(ExtensionLoginButton);
const UnsetLedgerLoginButton = unsetButton(LedgerLoginButton);
const UnsetWebWalletLoginButton = unsetButton(WebWalletLoginButton);

const GalleryCell = styled.div`
  width: 500px;
  height: 500px;
  max-width: 80vw;
  max-height: 80vh;
  perspective: 1000px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.4s;
  transition-delay: 0.2s;
  transform-style: preserve-3d;
  transform: ${(props) =>
    props.flipped ? "rotateY(180deg)" : "rotateY(0deg)"};
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const CardFaceFront = styled(CardFace)``;
const CardFaceBack = styled(CardFace)`
  transform: rotateY(180deg);
`;

const CardContents = styled.div`
  overflow-y: auto;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

function LoginBox() {
  const loginBoxContext = useContext(LoginBoxContext);
  const [isFlipped, setFlipped] = useState(false);
  useEffect(() => {
    if (loginBoxContext.isOpen) {
      setTimeout(() => {
        setFlipped(true);
      }, 100);
    }
    return () => {
      setFlipped(false);
    };
  }, [loginBoxContext.isOpen]);

  return (
    <Dialog
      onClose={() => loginBoxContext.closeLoginBox()}
      open={loginBoxContext.isOpen}
      PaperProps={{
        sx: {
          backgroundImage: "none",
          overflow: "hidden",
        },
      }}
    >
      <GalleryCell>
        <Wrapper flipped={isFlipped}>
          <CardFaceFront>
            <CardContents>
              <Box
                component="img"
                src="images/x.png"
                sx={{ width: "100%" }}
              ></Box>
            </CardContents>
          </CardFaceFront>
          <CardFaceBack>
            <CardContents>
              <Stack
                spacing={1}
                height="100%"
                display="flex"
                width="90%"
                alignItems="center"
                justifyContent="center"
              >
                <UnsetWalletConnectLoginButton
                  callbackRoute="/"
                  nativeAuth={true}
                  isWalletConnectV2={true}
                  unlockRoute="/"
                  onClick={console.log}
                >
                  xPortal login
                </UnsetWalletConnectLoginButton>
                <UnsetExtensionLoginButton
                  callbackRoute="/"
                  nativeAuth={true}
                  onClick={console.log}
                >
                  Extension login
                </UnsetExtensionLoginButton>
                <UnsetLedgerLoginButton
                  callbackRoute="/"
                  nativeAuth={true}
                  onClick={console.log}
                >
                  Ledger login
                </UnsetLedgerLoginButton>
                <UnsetWebWalletLoginButton
                  callbackRoute="/"
                  nativeAuth={true}
                  onClick={console.log}
                >
                  Web Wallet login
                </UnsetWebWalletLoginButton>
              </Stack>
            </CardContents>
          </CardFaceBack>
        </Wrapper>
      </GalleryCell>
    </Dialog>
  );
}

export default LoginBox;
