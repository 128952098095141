import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Sidebar from "@/layouts/DashboardLayout/sidebar";
import { SIDEBAR_WIDTH } from "@/layouts/DashboardLayout/constants";
import Navbar from "@/layouts/DashboardLayout/navbar";
import breakpoints from "@/theme/base/breakpoints";
import Footer from "./footer";

function DashboardLayout({ children }) {
  return (
    <Box>
      {/* <Sidebar
        sidebarOpen={sidebarOpen}
        sidebarClose={() => setSidebarOpen(false)}
      /> */}
      <Box
        sx={({ breakpoints, transitions }) => ({
          display: "flex",
          flexDirection: "column",
          minHeight: "100dvh",
          ml: 0,
          px: 2,
          pt: 2,
          transition: transitions.create("margin", {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shortest,
          }),
        })}
      >
        <Navbar />
        <Box sx={{ flex: 1 }}>{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
