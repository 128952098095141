import ValueSelector from "@/components/valueSelector";
import DashboardLayout from "@/layouts/DashboardLayout";
import { withProviders } from "@/util/withProviders";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MintPageContext, MintPageProvider } from "./MintPageContext";
import { formatAmount, refreshAccount } from "@multiversx/sdk-dapp/utils";
import mintContract from "@/contracts/mint";
import {
  useGetAccountInfo,
  useGetNetworkConfig,
  useGetSuccessfulTransactions,
} from "@multiversx/sdk-dapp/hooks";
import { Address } from "@multiversx/sdk-core";
import {
  removeAllSignedTransactions,
  sendTransactions,
} from "@multiversx/sdk-dapp/services";
import { useQueryClient } from "@tanstack/react-query";

const BASE_GAS = 13000000;

function Mint() {
  const queryClient = useQueryClient();

  const network = useGetNetworkConfig();
  const { account } = useGetAccountInfo();

  const mintPageContext = useContext(MintPageContext);

  const [selectedValue, setSelectedValue] = useState(0);

  const handleBuy = async () => {
    if (selectedValue === 0) {
      return;
    }

    await refreshAccount();
    const transaction = mintContract.methods
      .mint([selectedValue])
      .withValue(mintPageContext.price.multipliedBy(selectedValue))
      .withGasLimit(BASE_GAS + (BASE_GAS / 2) * (selectedValue - 1))
      .withChainID(network.chainID);

    const transactionFinal = transaction.buildTransaction();
    transactionFinal.sender = new Address(account.address);

    const { sessionId } = await sendTransactions({
      transactions: [transactionFinal],
      transactionsDisplayInfo: {
        processingMessage: "Buying",
        errorMessage: "An error has occured ",
        successMessage: "Successful",
        transactionDuration: 10000,
      },
    });

    setSelectedValue(0);
  };

  const a = useGetSuccessfulTransactions();
  useEffect(() => {
    const sortedTxs = a.successfulTransactionsArray
      .sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
      .map((t) => t[1].transactions[0]);

    if (sortedTxs.length > 0) {
      queryClient.invalidateQueries({ queryKey: ["mintData"] });
      removeAllSignedTransactions();
    }
  }, [a.successfulTransactions]);

  return (
    <DashboardLayout>
      <Container
        maxWidth="xs"
        sx={{
          py: 2,
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box width="100%" display="flex" justifyContent="center">
          <Box component="img" src="images/xFirstLogo.png" height="6rem" />
        </Box>
        <Paper elevation={4} sx={{ p: 3 }}>
          <Stack>
            <Box
              component="img"
              src="images/9.png"
              sx={{ maxWidth: "100%", borderRadius: 1 }}
            />
            <Stack spacing={4}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h6" textAlign="center">
                      Price
                    </Typography>
                    <Box
                      backgroundColor={(theme) => theme.palette.primary.main}
                      fontWeight="bold"
                      borderRadius={"2em"}
                      p={1}
                      textAlign="center"
                    >
                      {formatAmount({ input: mintPageContext.price })} EGLD
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={{ mt: 8 }}>
                    <Typography variant="h6" textAlign="center">
                      Limit
                    </Typography>
                    <Box
                      backgroundColor={(theme) => theme.palette.primary.main}
                      fontWeight="bold"
                      borderRadius={"2em"}
                      p={1}
                      textAlign="center"
                    >
                      {mintPageContext.limit.toString().padStart(3, "0")}
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6" textAlign="center">
                      Supply
                    </Typography>
                    <Box
                      backgroundColor={(theme) => theme.palette.primary.main}
                      fontWeight="bold"
                      borderRadius={"2em"}
                      py={1}
                      textAlign="center"
                    >
                      {mintPageContext.leftToMint}/{mintPageContext.totalSupply}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Box width="100%" display="flex" justifyContent="center">
                  <ValueSelector
                    value={selectedValue}
                    maxValue={mintPageContext.userLeftToMint || 0}
                    onChange={(v) => setSelectedValue(v)}
                  />
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ borderRadius: 10, px: 10 }}
                    onClick={handleBuy}
                    disabled={selectedValue === 0}
                  >
                    Mint for{" "}
                    {formatAmount({
                      input: mintPageContext.price.multipliedBy(selectedValue),
                    })}{" "}
                    EGLD
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </DashboardLayout>
  );
}

export default withProviders(MintPageProvider)(Mint);
