import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import {
  TransactionsToastList,
  SignTransactionsModals,
} from "@multiversx/sdk-dapp/UI";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Mint from "@/pages/mint";
import SignIn from "@/pages/sign-in";
import { NavbarProvider } from "./contexts/navbarContext";
import Main from "@/pages/main";
import Liquifi from "./pages/liquifi";
const queryClient = new QueryClient();
const routes = [
  {
    type: "menu-item",
    name: "Home",
    key: "home",
    path: "/",
    element: <Main />,
    authenticatedRoute: true,
  },
  {
    type: "menu-item",
    name: "Mint",
    key: "mint",
    path: "/mint",
    element: <Mint />,
    authenticatedRoute: true,
  },
  {
    type: "menu-item",
    name: "Liqui-Fi",
    key: "liquifi",
    path: "/liquifi",
    element: <Liquifi />,
    authenticatedRoute: true,
  },
  {
    type: "page",
    key: "sign-in",
    path: "/sign-in",
    element: <SignIn />,
    authenticatedRoute: false,
  },
];

const renderRoutes = () => {
  return routes.map((r) => {
    return <Route path={r.path} key={r.key} element={r.element} />;
  });
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#08fbae",
    },
    error: {
      main: "#fd000e",
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <DappProvider
    environment={import.meta.env.VITE_APP_ENV || "devnet"}
    customNetworkConfig={{
      walletConnectV2ProjectId: import.meta.env.VITE_APP_WC_V2,
    }}
  >
    <SignTransactionsModals />
    <TransactionsToastList />
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <NavbarProvider>
          <CssBaseline />
          <BrowserRouter>
            {/* <AuthenticatedRoutesWrapper routes={routes} unlockRoute="/sign-in"> */}

            <Routes>{renderRoutes()}</Routes>
            {/* </AuthenticatedRoutesWrapper> */}
          </BrowserRouter>
        </NavbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </DappProvider>
);
