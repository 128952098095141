import ValueSelector from "@/components/valueSelector";
import DashboardLayout from "@/layouts/DashboardLayout";
import { withProviders } from "@/util/withProviders";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LiquifiPageContext, LiquifiPageProvider } from "./LiquifiPageContext";
import {
  decodeBase64,
  formatAmount,
  refreshAccount,
} from "@multiversx/sdk-dapp/utils";
import {
  removeAllSignedTransactions,
  sendTransactions,
} from "@multiversx/sdk-dapp/services";

import { TokenPayment, Address } from "@multiversx/sdk-core";
import swapContract from "@/contracts/swap";
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig,
  useGetSuccessfulTransactions,
} from "@multiversx/sdk-dapp/hooks";
import { useQueryClient } from "@tanstack/react-query";
function Liquifi() {
  const queryClient = useQueryClient();

  const network = useGetNetworkConfig();

  const { isLoggedIn } = useGetLoginInfo();
  const { account } = useGetAccountInfo();

  const liquifiContext = useContext(LiquifiPageContext);
  const [selectedValueLiquifi, setSelectedValueLiquifi] = useState(0);
  const [selectedValueBuy, setSelectedValueBuy] = useState(0);
  console.log(
    "🚀 ~ file: index.jsx:48 ~ Liquifi ~ selectedValueBuy:",
    selectedValueBuy
  );

  const formatedSftPrice = formatAmount({
    input: liquifiContext.sftPrice,
    showLastNonZeroDecimal: false,
  });

  const handleBuy = async () => {
    if (selectedValueBuy === 0) {
      return;
    }

    await refreshAccount();
    const transaction = swapContract.methods
      .buySftWithEgld([selectedValueBuy])
      .withValue(liquifiContext.sftPrice.multipliedBy(selectedValueBuy))
      .withGasLimit(5_000_000)
      .withChainID(network.chainID);

    const transactionFinal = transaction.buildTransaction();
    transactionFinal.sender = new Address(account.address);

    const { sessionId } = await sendTransactions({
      transactions: [transactionFinal],
      transactionsDisplayInfo: {
        processingMessage: "Buying",
        errorMessage: "An error has occured ",
        successMessage: "Successful",
        transactionDuration: 10000,
      },
    });

    setSelectedValueBuy(0);
  };

  const handleLiquifi = async () => {
    if (selectedValueLiquifi === 0) {
      return;
    }
    const payments = TokenPayment.semiFungible(
      import.meta.env.VITE_APP_SFT_TICKER,
      import.meta.env.VITE_APP_SFT_NONCE,
      selectedValueLiquifi
    );

    await refreshAccount();
    const transaction = swapContract.methods
      .BuyEgldWithSft([selectedValueLiquifi])
      .withSingleESDTNFTTransfer(payments, new Address(account.address))
      .withGasLimit(5_000_000)
      .withChainID(network.chainID);

    const transactionFinal = transaction.buildTransaction();
    transactionFinal.sender = new Address(account.address);

    const { sessionId } = await sendTransactions({
      transactions: [transactionFinal],
      transactionsDisplayInfo: {
        processingMessage: "Liqui-Fi",
        errorMessage: "An error has occured ",
        successMessage: "Successful",
        transactionDuration: 10000,
      },
    });

    setSelectedValueLiquifi(0);
  };

  const a = useGetSuccessfulTransactions();
  useEffect(() => {
    const sortedTxs = a.successfulTransactionsArray
      .sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
      .map((t) => t[1].transactions[0]);

    if (sortedTxs.length > 0) {
      queryClient.invalidateQueries({ queryKey: ["liquifiData"] });
      removeAllSignedTransactions();
    }
  }, [a.successfulTransactions]);

  return (
    <DashboardLayout>
      <Container
        maxWidth="sm"
        sx={{
          py: 2,
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box width="100%" display="flex" justifyContent="center">
          <Box component="img" src="images/xNodesLogo.png" height="6rem" />
        </Box>
        <Paper
          elevation={4}
          sx={{
            p: 3,
            width: "100%",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={6} md={6}>
              <Stack spacing={8}>
                <Box>
                  <Typography variant="h6" textAlign="center">
                    Price
                  </Typography>
                  <Box
                    backgroundColor={(theme) => theme.palette.error.main}
                    color="white"
                    fontWeight="bold"
                    borderRadius={"2em"}
                    p={1}
                    textAlign="center"
                  >
                    {formatedSftPrice} EGLD
                  </Box>
                  <Divider sx={{ pt: 1 }} />
                </Box>
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    <ValueSelector
                      value={selectedValueLiquifi}
                      maxValue={isLoggedIn ? liquifiContext.sftBalanceUser : 0}
                      onChange={(v) => setSelectedValueLiquifi(v)}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ fontSize: "1.1em", borderRadius: "1.5em" }}
                      color="error"
                      fullWidth
                      onClick={handleLiquifi}
                      disabled={selectedValueLiquifi === 0}
                    >
                      Liqui-Fi
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} md={6}>
              <Stack spacing={8}>
                <Box>
                  <Typography variant="h6" textAlign="center">
                    SSFT&trade;
                  </Typography>
                  <Box
                    backgroundColor={(theme) => theme.palette.error.main}
                    color="white"
                    fontWeight="bold"
                    borderRadius={"2em"}
                    p={1}
                    textAlign="center"
                  >
                    {liquifiContext.sftCount.toString().padStart(2, "0")}
                  </Box>
                  <Divider sx={{ pt: 1 }} />
                </Box>
                <Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    <ValueSelector
                      value={selectedValueBuy}
                      maxValue={isLoggedIn ? liquifiContext.sftCount : 0}
                      onChange={(v) => setSelectedValueBuy(v)}
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ fontSize: "1.1em", borderRadius: "1.5em" }}
                      color="error"
                      fullWidth
                      onClick={handleBuy}
                      disabled={selectedValueBuy === 0}
                    >
                      Buy
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Typography textAlign="center" sx={{ mt: 4 }} fontWeight="bold">
            Liquidate your{" "}
            <Box component="span" color={(theme) => theme.palette.error.main}>
              SSFT
            </Box>
            &trade; in {formatedSftPrice} EGLD increments
          </Typography>
          <Typography textAlign="center" fontWeight="bold">
            Buy your{" "}
            <Box component="span" color={(theme) => theme.palette.error.main}>
              SSFT
            </Box>
            &trade; for {formatedSftPrice} EGLD each
          </Typography>
        </Paper>
      </Container>
    </DashboardLayout>
  );
}

export default withProviders(LiquifiPageProvider)(Liquifi);
