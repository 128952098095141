import DashboardLayout from "@/layouts/DashboardLayout";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Main() {
  const navigate = useNavigate();

  const onClick = (url) => {
    navigate(url);
  };

  return (
    <DashboardLayout>
      <Container maxWidth="md" sx={{ py: 2 }}>
        <Grid container spacing={{ sm: 2 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ width: "100%" }}>
              <CardActionArea onClick={() => onClick("/mint")}>
                <CardContent>
                  <Stack spacing={2}>
                    <Box display="flex" justifyContent="center">
                      <Box
                        component="img"
                        src="images/xFirstLogo.png"
                        sx={{ height: "5em" }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Box
                        component="img"
                        src="images/7.png"
                        sx={{ height: "20em" }}
                      />
                    </Box>
                    <Button disableRipple component="div" variant="contained">
                      Builder Mint
                    </Button>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ width: "100%" }}>
              <CardActionArea onClick={() => onClick("/liquifi")}>
                <CardContent>
                  <Stack spacing={2}>
                    <Box display="flex" justifyContent="center">
                      <Box
                        component="img"
                        src="images/xNodesLogo.png"
                        sx={{ height: "5em" }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Box
                        component="img"
                        src="images/8.png"
                        sx={{ height: "20em" }}
                      />
                    </Box>
                    <Button
                      disableRipple
                      component="div"
                      variant="contained"
                      color="error"
                    >
                      Liqui-Fi
                    </Button>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
}

export default Main;
