import {
  AbiRegistry,
  Address,
  SmartContract,
  SmartContractAbi,
} from "@multiversx/sdk-core/out";
import abiJson from "./elven-nft-minter.abi.json";

const abiRegistry = AbiRegistry.create(abiJson);
const scAbi = new SmartContractAbi(abiRegistry, ["MainModule"]);
const mintContract = new SmartContract({
  address: new Address(import.meta.env.VITE_APP_MINT_CONTRACT),
  abi: scAbi,
});
export default mintContract;
