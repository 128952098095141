import {
  AbiRegistry,
  Address,
  SmartContract,
  SmartContractAbi,
} from "@multiversx/sdk-core/out";
import abiJson from "./egld-sft-swap.abi.json";

const abiRegistry = AbiRegistry.create(abiJson);
const scAbi = new SmartContractAbi(abiRegistry, ["MainModule"]);
const swapContract = new SmartContract({
  address: new Address(import.meta.env.VITE_APP_SWAP_CONTRACT),
  abi: scAbi,
});
export default swapContract;
