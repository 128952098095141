import { Dialog } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoginBox from "./LoginBox";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";

const LoginBoxContext = createContext({
  openLoginBox: () => {},
  closeLoginBox: () => {},
  isOpen: false,
});

function LoginBoxProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const { isLoggedIn } = useGetLoginInfo();

  useEffect(() => {
    if (isLoggedIn) {
      setIsOpen(false);
    }
  }, [isLoggedIn]);

  return (
    <LoginBoxContext.Provider
      value={{
        openLoginBox: () => {
          setIsOpen(true);
        },
        closeLoginBox: () => {
          setIsOpen(false);
        },
        isOpen,
      }}
    >
      {children}
      <LoginBox />
    </LoginBoxContext.Provider>
  );
}

LoginBoxProvider.propTypes = {
  children: PropTypes.node,
};

export { LoginBoxContext, LoginBoxProvider };
