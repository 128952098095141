import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Drawer, IconButton, Stack } from "@mui/material";
import { NAVBAR_HEIGHT } from "@/layouts/DashboardLayout/constants";

import { logout } from "@multiversx/sdk-dapp/utils";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { LoginBoxContext } from "@/contexts/LoginBox";
import { Close, Menu } from "@mui/icons-material";
import { NavbarContext } from "@/contexts/navbarContext";
import { NavLink, redirect, useNavigate } from "react-router-dom";
function Navbar({ onOpenSidebar }) {
  const { isLoggedIn } = useGetLoginInfo();
  const loginBoxContext = useContext(LoginBoxContext);
  const navbarContext = useContext(NavbarContext);
  const navigate = useNavigate();

  return (
    <Box
      sx={({ palette, breakpoints }) => ({
        height: NAVBAR_HEIGHT,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        [breakpoints.up("xl")]: {},
      })}
    >
      <Box display="flex">
        <Box
          component="img"
          src="images/xFirstLogo.png"
          height="4rem"
          ml={-2}
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer" }}
        ></Box>
      </Box>
      <Stack direction="row" spacing={2}>
        {isLoggedIn ? (
          <Button variant="contained" color="error" onClick={() => logout("/")}>
            Logout
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/sign-in")}
          >
            Login
          </Button>
        )}
      </Stack>
    </Box>
  );
}

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default Navbar;
