import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import boxShadows from "@/theme/base/boxShadows";
import { SIDEBAR_WIDTH } from "@/layouts/DashboardLayout/constants";
import linearGradient from "@/theme/functions/linearGradient";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

function Sidebar({ sidebarOpen, sidebarClose }) {
  return (
    <Box
      sx={({ palette, breakpoints, transitions, zIndex,  }) => ({
        display: "inline",
        backgroundImage: linearGradient(
          palette.sidebar.dark,
          palette.sidebar.main
        ),
        p: 2,
        boxShadow: boxShadows.md,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: "100vh",
        position: "fixed",
        transform: sidebarOpen
          ? "translateX(0)"
          : `translateX(-${SIDEBAR_WIDTH})`,
        transition: transitions.create("transform", {
          easing: transitions.easing.sharp,
          duration: transitions.duration.shortest,
        }),
        width: SIDEBAR_WIDTH,
        zIndex: zIndex.appBar,

        [breakpoints.up("xl")]: {
          transform: "translateX(0)",
        },
      })}
    >
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Box flex={1} textAlign="center">
            <Typography variant="h3" fontWeight="600">
              DAPP
            </Typography>
          </Box>
          <IconButton
            onClick={sidebarClose}
            sx={({ breakpoints }) => ({
              display: "inherit",
              [breakpoints.up("xl")]: {
                display: "none",
              },
            })}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
      </Box>
      Sidebar
    </Box>
  );
}

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  sidebarClose: PropTypes.func,
};

export default Sidebar;
