import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig,
} from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out/proxyNetworkProvider";
import { ApiNetworkProvider } from "@multiversx/sdk-network-providers";
import { Address, ResultsParser } from "@multiversx/sdk-core/out";
import BigNumber from "bignumber.js";
import axios from "axios";
import mintContract from "@/contracts/mint";
// import axios from "axios";

const MintPageContext = createContext({
  enabled: false,
  leftToMint: 0,
  totalSupply: 0,
  price: new BigNumber(0),
  limit: 0,

  userLeftToMint: 0,
});

const resultsParser = new ResultsParser();

function MintPageProvider({ children }) {
  const {
    network: { apiAddress, apiTimeout, ...rest },
    chainID,
  } = useGetNetworkConfig();

  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const provider = new ApiNetworkProvider(apiAddress);

  const getScData = async () => {
    const query = mintContract.methods.getScDetails([]).buildQuery();
    const queryResponse = await provider.queryContract(query);

    const endpointDef = mintContract.getEndpoint("getScDetails");
    const { firstValue } = resultsParser.parseQueryResponse(
      queryResponse,
      endpointDef
    );

    const values = firstValue?.valueOf();

    return {
      enabled: values?.["enabled"] || true,
      leftToMint: values?.["left_to_mint"].toNumber() || 0,
      totalSupply: values?.["total_supply"].toNumber() || 0,
      price: values?.["price"] || new BigNumber(0),
      limit: values?.["limit"].toNumber() || 0,
    };
  };

  const getUserData = async () => {
    const query = mintContract.methods.getUserDetails([address]).buildQuery();
    const queryResponse = await provider.queryContract(query);

    const endpointDef = mintContract.getEndpoint("getUserDetails");
    const { firstValue } = resultsParser.parseQueryResponse(
      queryResponse,
      endpointDef
    );

    const values = firstValue?.valueOf();

    return {
      userLeftToMint: values?.["left_to_mint"].toNumber() || 0,
    };
  };

  const { data, isInitialLoading, error } = useQuery({
    queryKey: ["mintData", address],
    queryFn: async () => {
      const scData = await getScData();

      let userDetails = {};
      if (isLoggedIn) {
        userDetails = await getUserData(address);
      }

      return {
        ...scData,
        ...userDetails,
      };
    },
    refetchInterval: 60 * 1000,
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <MintPageContext.Provider
      value={{
        enabled: data?.["enabled"] || false,
        leftToMint: data?.["leftToMint"] || 0,
        totalSupply: data?.["totalSupply"] || 0,
        price: data?.["price"] || new BigNumber(0),
        limit: data?.["limit"] || 0,

        userLeftToMint: data?.["userLeftToMint"] || 0,
      }}
    >
      {children}
    </MintPageContext.Provider>
  );
}

MintPageProvider.propTypes = {
  children: PropTypes.node,
};

export { MintPageContext, MintPageProvider };
