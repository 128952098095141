import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, Link, Stack, Typography } from "@mui/material";
import { NAVBAR_HEIGHT } from "@/layouts/DashboardLayout/constants";
import boxShadows from "@/theme/base/boxShadows";
import borders from "@/theme/base/borders";
import { logout } from "@multiversx/sdk-dapp/utils";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import { LoginBoxContext } from "@/contexts/LoginBox";

function Footer() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Link
        href="https://twitter.com/LuckyBirdsLabs"
        target="_blank"
        rel="noreferrer"
        variant="caption"
      >
        Made with ❤ by Lucky Birds Labs
      </Link>
    </Box>
  );
}

export default Footer;
