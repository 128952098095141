import { Dialog, useMediaQuery } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";

const NavbarContext = createContext({
  openNavbar: () => {},
  closeNavbar: () => {},
  toggleNavbar: () => {},
  isOpen: false,
});

function NavbarProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md")); // Variable for media query
  useEffect(() => {
    if (matches) {
      setIsOpen(false);
    }
  }, [matches]);
  return (
    <NavbarContext.Provider
      value={{
        openNavbar: () => {
          setIsOpen(true);
        },
        closeNavbar: () => {
          setIsOpen(false);
        },
        toggleNavbar: () => {
          setIsOpen(!isOpen);
        },
        isOpen,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
}

NavbarProvider.propTypes = {
  children: PropTypes.node,
};

export { NavbarContext, NavbarProvider };
