import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import {
  useGetAccountInfo,
  useGetLoginInfo,
  useGetNetworkConfig,
} from "@multiversx/sdk-dapp/hooks";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers/out/proxyNetworkProvider";
import { ApiNetworkProvider } from "@multiversx/sdk-network-providers";
import swapContract from "@/contracts/swap";
import { Address, ResultsParser } from "@multiversx/sdk-core/out";
import BigNumber from "bignumber.js";
import axios from "axios";
// import axios from "axios";

const LiquifiPageContext = createContext({
  contractEnabled: true,
  sftCount: 0,
  sftPrice: new BigNumber(0),
  sftBalanceUser: 0,
});

const resultsParser = new ResultsParser();

function LiquifiPageProvider({ children }) {
  const {
    network: { apiAddress, apiTimeout, ...rest },
    chainID,
  } = useGetNetworkConfig();

  const { address } = useGetAccountInfo();
  const { isLoggedIn } = useGetLoginInfo();
  const provider = new ApiNetworkProvider(apiAddress);

  const getScData = async () => {
    const query = swapContract.methods.getScDetails([]).buildQuery();
    const queryResponse = await provider.queryContract(query);

    const endpointDef = swapContract.getEndpoint("getScDetails");
    const { firstValue } = resultsParser.parseQueryResponse(
      queryResponse,
      endpointDef
    );

    const values = firstValue?.valueOf();

    return {
      contractEnabled: values?.["enabled"] || true,
      sftCount: values?.["sft_count"].toNumber() || 0,
      sftPrice: values?.["sft_price"] || new BigNumber(0),
    };
  };

  const {
    data: swapData,
    isInitialLoading,
    error,
  } = useQuery({
    queryKey: ["liquifiData", "contract"],
    queryFn: async () => {
      const scData = await getScData();

      return {
        ...scData,
      };
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const { data: sftBalanceUser, isInitialLoading: isLoadingSftBalance } =
    useQuery({
      queryKey: ["liquifyData", "user", address],
      queryFn: async () => {
        const token = await axios.get(
          `${apiAddress}/accounts/${address}/nfts?collections=${
            import.meta.env.VITE_APP_SFT_TICKER
          }&fields=balance`
        );

        return parseInt(token?.data?.[0]?.balance) || 0;
      },
      enabled: isLoggedIn,
    });

  return (
    <LiquifiPageContext.Provider
      value={{
        contractEnabled: swapData?.contractEnabled || true,
        sftCount: swapData?.sftCount || 0,
        sftPrice: swapData?.sftPrice || new BigNumber(0),
        sftBalanceUser: sftBalanceUser,
      }}
    >
      {children}
    </LiquifiPageContext.Provider>
  );
}

LiquifiPageProvider.propTypes = {
  children: PropTypes.node,
};

export { LiquifiPageContext, LiquifiPageProvider };
